<template>
  <div class="subpage">
    <div class="title">{{ $route.meta }}</div>
    <div class="content">
      <router-link v-for="(item,i) in list" :to="'/specialtyInfo?sid='+item.specialty_code" :key="i" target="_blank" class="college">
        <el-row>
          <el-col :span="21">
            <p class="row">
              <span class="name">{{ item.name }}</span>
            </p>
            <p class="row info">
              <span>学历层次：{{ item.degree == 1?'专科':'本科' }}</span>
              <span v-if="item.grandParent&&item.parent">专业类别：{{ item.grandParent.name }}>{{ item.parent.name }}</span>
            </p>
          </el-col>
          <el-col :span="3">
            <div class="guanzhu" @click.stop.prevent="attention(item,i)">取消关注</div>
          </el-col>
        </el-row>
      </router-link>
      <div class="pagination">
        <el-pagination :page-size="pageSize" :total="total" class="page-box" background layout="prev, pager, next" hide-on-single-page @current-change="pageChange" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AttSpecialty",
  data() {
    return {
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    attention(item, i) {
      this.$fecth
        .post("user_info/addAttention", {
          id: item.specialty_code,
          type: 1,
        })
        .then(() => {
          this.list.splice(i, 1);
          this.$message({
            offset: 400,
            showClose: true,
            message: "取消关注",
            type: "success",
          });
        });
    },
    getList() {
      this.$fecth
        .post("user_info/getAttentionList", {
          type: 1,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.list = res.list;
          this.total = res.count;
        });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.subpage {
  background-color: #ffffff;
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
  .content {
    padding: 40px;
    min-height: 430px;
  }
  .college {
    display: block;
    border-radius: 6px;
    padding: 20px 0;
    background-color: #ffffff;
    border-bottom: 1px solid #f2f2f2;
    .row {
      height: 40px;
      line-height: 40px;

      span {
        margin-right: 30px;
      }

      &.info {
        color: #b8b8b8;
      }

      .name {
        font-size: 24px;
        color: #757575;
      }
    }

    .guanzhu {
      border: 1px solid #8c939d;
      color: #8c939d;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50px;
      margin-top: 20px;
    }
  }
  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }
}
</style>
